<template>
  <div class="LAccordionParent">
    <div class="LAccordionItem" :class="selected[0] ? 'LActive' : ''">
      <div @click="selected[0] = !selected[0]" class="LAccordionItemHead">
        <span>Informații creare utilizator</span>
        <div>
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem;" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div class="LAccordionItemBody" :style="ACHcomputed['AC1']">
        <div ref="AC1" class="CNX_List">
          <h3 v-if="!vers[1]" class="prettyH4">
            <i class="fas fa-times"></i> Nu s-a gasit informatia
          </h3>
          <div
            v-else
            style="padding-left: 1rem;border-left: 3px solid #00bcd4;"
          >
            <p>
              Creat de către:
              {{
                vers[1].modifier +
                  (vers[1].modifierRole
                    ? `, ${changeRoleView(vers[1].modifierRole)}`
                    : '')
              }}
            </p>
            <p>
              Data și ora creării:
              {{ toDateAndTime(vers[1].realModTime, true) }}
            </p>
            <p>
              Data și ora activării:
              <span v-html="vers[1].activationTime"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="LAccordionItem" :class="selected[1] ? 'LActive' : ''">
      <div @click="selected[1] = !selected[1]" class="LAccordionItemHead">
        <span
          >Informații modificari utilizator ({{
            userModifications.length
          }})</span
        >
        <div>
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem;" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div class="LAccordionItemBody" :style="ACHcomputed['AC2']">
        <div ref="AC2" class="TBParent">
          <h3 v-if="!userModifications.length" class="prettyH4">
            <i class="fas fa-times"></i> Nu sunt modificări
          </h3>
          <template v-else v-for="(e, i) in userModifications">
            <table :key="i" class="NRBLTD" style="max-height: 24rem;">
              <thead>
                <th>{{ e.colName }}</th>
                <th>Modificat de către</th>
                <th>Rol</th>
                <th>Data modificării</th>
                <th style="width: 7rem;">{{ e.reasonText }}</th>
              </thead>
              <tbody>
                <tr :key="i">
                  <td v-html="e.colVal"></td>
                  <td>{{ e.modifier }}</td>
                  <td>{{ e.modifierRole }}</td>
                  <td>{{ e.modificationDate }}</td>
                  <td style="text-align: right;">
                    <template v-if="e.showReason">
                      <i
                        v-if="e.reason"
                        @click="selectReason(i)"
                        :class="
                          `fas fa-${
                            reasonIndex === i ? 'minus' : 'plus'
                          }-circle clickableI`
                        "
                        style="width: 2.5rem;text-align: center;"
                      ></i>
                      <i
                        v-else
                        class="fas fa-times"
                        style="color: red;width: 2.5rem;text-align: center;"
                        v-tooltip="'Nu a fost indicat motivul'"
                      ></i>
                    </template>
                  </td>
                </tr>
                <tr v-if="reasonIndex === i" :key="i + 0.5">
                  <td
                    :colspan="e.showReason ? 5 : 4"
                    style="padding-left: 0;padding-right: 0;background-color: #fff;"
                  >
                    <pre style="margin: 0.5rem 0;">{{ e.reason }}</pre>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
        </div>
      </div>
    </div>
    <div class="LAccordionItem" :class="selected[2] ? 'LActive' : ''">
      <div @click="selected[2] = !selected[2]" class="LAccordionItemHead">
        <span>Informații roluri ({{ vers[0].length }})</span>
        <div>
          <i class="fas fa-times"></i>
          <i style="font-size: 2rem;" class="fas fa-chevron-down"></i>
        </div>
      </div>
      <div class="LAccordionItemBody" :style="ACHcomputed['AC3']">
        <div ref="AC3" class="CNX_List">
          <h3 v-if="!vers[0].length" class="prettyH4">
            <i class="fas fa-times"></i> Nu sunt modificări
          </h3>
          <div v-else v-for="(e, i) in vers[0]" :key="i">
            <span>{{ e.message }}</span>
            <p v-if="e.role">
              <i class="fas fa-user-tag" style="width: 2rem;"></i>
              {{ changeRoleView(e.role.modifiedRoleName || e.role.role) + e.roleStatusLabel }}
            </p>
            <p v-if="e.role.institution || e.role.department">
              <span v-if="e.role.institution"
                ><img
                  src="@/assets/media/institutions.svg"
                  style="width: 2rem;"
                  alt="Institutie"
                />
                {{ e.role.institution }}</span
              >
              <span v-if="e.role.department"
                >,
                <img src="@/assets/media/departments.svg" alt="Institutie" />
                {{ e.role.department }}</span
              >
            </p>
            <div v-if="e.modifier || e.modifierRole">
              <div class="hr1" style="margin: 0.25rem 0;"></div>
              <i
                class="fas fa-user-tie"
                style="margin-right: 0.5rem;width: 2rem;"
              ></i>
              <span v-if="e.modifier">{{ e.modifier }}</span>
              <span v-if="e.modifierRole"
                >, {{ changeRoleView(e.modifierRole) }}</span
              >
            </div>
            <p style="padding-left: 2.5rem;">{{ e.modificationDate }}</p>
            <div class="CNX_indexTag prettyH4">#{{ i + 1 }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@/assets/usersHistory.css"></style>
<style src="@/assets/LAcordion.css"></style>

<script>
import LAcordion from '@/mixins/LAcordion.js'

export default {
  mixins: [LAcordion],
  props: {
    versions: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      reasonIndex: -1,
      roleStatus: false,
      showCreationInfo: true,
      selected: {
        0: false,
        1: false,
        2: false,
      },
    }
  },
  computed: {
    userModifications() {
      const x = Object.entries(this.parsedVersions)
      const keysMap = [
        [
          'userStatus',
          'Status Utilizator',
          this.prepareStatusView,
          {
            showReason: true,
            reasonText: 'Motivul',
          },
        ],
        ['first_name', 'Modificare Nume'],
        ['last_name', 'Modificare Prenume'],
        ['cnp', 'Modificare CNP'],
        ['email', 'Modificare Username'],
        ['emailForCommunication', 'Modificare Email'],
        ['phone', 'Modificare Telefon'],
      ]
      const prepare = []

      for (let e of x) {
        const q = e[1].updateDetails
        if (!this.isObject(q)) {
          continue
        }

        for (let key of keysMap) {
          let val = q[key[0]]
          if (val) {
            if (key[2]) {
              val = key[2](val)
            }
            const keyOptions = key[3] || {}

            let modifier = '-'
            let modifierRole = '-'
            if (e[1].byUser) {
              modifier = this.readUserObject(e[1].byUser)
              if (e[1].byUser.role?.role) {
                modifierRole = this.changeRoleView(e[1].byUser.role.role)
              }
            }
            prepare.push({
              showReason: !!keyOptions.showReason,
              reasonText: keyOptions.reasonText || '',
              modifier,
              modifierRole,
              colName: key[1],
              colVal: val,
              modificationDate: this.toDateAndTime(e[0]),
              realModTime: e[0],
              reason: this.isString(e[1].updateDetails.actionReason)
                ? e[1].updateDetails.actionReason.trim()
                : '',
            })
          }
        }
      }

      return prepare
    },
    parsedVersions() {
      let x = this.JSONvalide(this.versions) ? JSON.parse(this.versions) : null

      return typeof x === 'object' && x !== null ? x : {}
    },
    vers() {
      const x = this.parsedVersions

      let justCreated = null
      let activated = null
      const roles = []

      Object.entries(x).forEach(e => {
        const data = e[1].updateDetails || {}
        const urs = data.history?.userRoleStatus || ''
        const y = {
          modifier: e[1].byUser
            ? this.readUserObject(e[1].byUser)
            : '',
          modifierRole: e[1].byUser?.role?.role || '',
          modificationDate: this.toDateAndTime(e[0]),
          realModTime: e[0],
          reason: this.isString(data.actionReason)
            ? data.actionReason.trim()
            : '',
          roleStatus: urs,
          roleStatusLabel: urs ? `, ${ ({
            'active': 'status activ',
            'disabled': 'status dezactivat',
            'unconfirmed': 'status neconfirmat',
          }[urs]) }` : '',
        }
        if (
          data.actionReason ==
          'user set password after first login'
        ) {
          activated = e[0]
          return
        }
        const x = data.history
        if (x) {
          y.role = x
          y.role.institution = y.role.modifiedRoleInstitution?.name || y.role.institution
          y.role.department = y.role.modifiedRoleDepartment?.name || y.role.department

          if (y.role.institution == 'nu exista') y.role.institution = ''
          if (y.role.department == 'nu exista') y.role.department = ''
          
          y.message =
            {
              'role delete': 'A fost șters un rol existent.',
              'role add': 'A fost creat un rol nou.',
              'role activation/desacivation': `A fost ${({
                'disabled': 'dezactivat',
                'active': 'activat'
              }[y.role.userRoleStatus] || 'activat sau dezactivat')} un rol.`,
              'role confirmation': 'A fost confirmat un rol nou.',
              'role unconfirmed': 'A fost confirmat un rol nou.',
              'role disabled': 'A fost dezactivat un rol.',
              'role active': 'A fost activat un rol.',
            }[y.reason] || y.reason
          roles.push(y)
        } else if (data?.message == 'just created.') {
          y.status = 'neconfirmat'
          justCreated = y
        }
      })
      if (justCreated) {
        if (activated) {
          justCreated.activationTime = this.toDateAndTime(activated, true)
        } else {
          justCreated.activationTime =
            '<i>Utilizatorul încă nu este activat</i>'
        }
      }

      return [roles, justCreated]
    },
  },
  methods: {
    prepareStatusView(status) {
      status = this.beautifyUserStatus(status)
      const map = {
        Dezactivat: `<span style="color: red;"><b>Dezactivat</b> <i class="fas fa-times-circle"></i></span>`,
        Activat: `<span style="color: green;"><b>Activat</b> <i class="fas fa-check-circle"></i></span>`,
        neconfirmat: `<span style="color: #ff9800;"><b>Neconfirmat</b> <i class="far fa-clock"></i></span>`,
      }

      return map[status] || status
    },
    beautifyUserStatus(status) {
      const statMap = {
        disabled: 'Dezactivat',
        active: 'Activat',
        reset: 'Activat',
      }

      return statMap[status] || status
    },
    selectReason(index) {
      if (!Number.isInteger(index)) return
      this.reasonIndex = index === this.reasonIndex ? -1 : index
    },
  },
}
</script>
